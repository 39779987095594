.suggestions-list li:hover {
    background-color: blue;
    color: white;
  }


  .results {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust 250px to the desired minimum card width */
    gap: 10px; /* Adjust the gap between cards */
  }



  .card {
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
  }
  .card img {
    object-fit: contain;
    max-width: 100%;
    max-height: 250px; /* Adjust this value to control the maximum image height */
  }


  .card-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card-info {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .card-info li {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .card-info strong {
    margin-right: 10px;
  }
