* {
    box-sizing: border-box;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    padding: 30px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
  }

 .errorz {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    color: #d93025;
  }

  li {
    margin-bottom: 5px;
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
  }

  input {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }

  button {
    font-size: 14px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
  }

  .button-green {
    background-color: #4caf50;
    color: #fff;
  }

  .button-green:hover {
    background-color: #3e8e41;
  }
