/* CarListingCard.css */

.car-listing-card {
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;

}

.car-listing-card:hover {
  transform: scale(1.05);
}

.car-listing-card__background {
  width: 100%;
  height: 500px;
  object-fit: contain;
}

.car-listing-card__content {
  padding: 0px;
}

.car-listing-card__content--container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.car-listing-card__title {
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.car-listing-card__price {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  background-color: #4caf50;
  color: #ffffff;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.car-listing-card-mileage{
  padding-left: 20px;
  margin: 4px;
}

.flow {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
