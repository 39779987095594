/* CarListingCreate.css */

.car-listing-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .car-listing-form h2 {
    margin-bottom: 20px;
  }

  .car-listing-form label {
    align-self: flex-start;
    margin-top: 10px;
  }

  .car-listing-form input {
    width: 100%;
    padding: 8px 12px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .car-listing-form button {
    width: 100%;
    margin-top: 20px;
    padding: 12px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }

  .car-listing-form button:hover {
    background-color: #0056b3;
  }

  .errors-list {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
  }

  .errors-list li {
    list-style: none;
  }

  .business-form-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .image-preview {
    margin-bottom: 10px;
    border-radius: 4px;
  }


  select {
    background: white;
  }

  option {
    padding: 4px 8px;
    margin: 1px 0;
    border-radius: 4px;
  }

  option:not(:last-child)::before {
    content: " ";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: currentColor;
  }

  option:last-child::before {
    display: none;
  }
