/* TODO Add site wide styles */

* {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    word-break: break-word !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }


/* Sitewide green button */
.button-green {
  background-color: #28a745; /* Green background */
  color: #ffffff; /* White text */
  font-weight: bold; /* Bold text */
  padding: 2px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
}

/* Sitewide green button hover state */
.button-green:hover {
  background-color: #218838; /* Darker green background */
}
