nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

nav li {
  margin-right: 10px;
  justify-content: space-evenly;
}


nav li:first-child {
  margin-right: auto;
}

nav a {
  text-decoration: none;
  color: inherit;
}

.logo {
  height: 40px;
  width: auto;
}

.menu-button {
  background-color: transparent;
  border: none;
  padding: 0;
  justify-content: end;
}

.menu-button-icon {
  width: 30px;
  height: 5px;
  background-color: #16c21e;
  margin: 6px 0;
}

.profile-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  width: 10%;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  list-style-type: none;
  margin-top: 15px;
  border-radius: 4px;
}

.profile-dropdown li {
  padding: 4px;
  padding-top: 6px;

}

.profile-dropdown button{
  background-color: #28a745; /* Green background */
  color: #ffffff; /* White text */
  font-weight: bold; /* Bold text */
  padding: 6px 6px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
}

.profile-dropdown button :hover{
  background-color: #218838; /* Darker green background */
}

.hidden {
  display: none;
}

.menu-button {
  background-color: #28a745; /* Green background */
  color: #ffffff; /* White text */
  padding: 6px 6px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.menu-button:hover {
  background-color: #218838; /* Darker green background */
}
