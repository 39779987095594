/* EditCarListing.css */

.edit-car-listing {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    z-index: 1;

  }

  .edit-car-listing form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .edit-car-listing input {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .edit-car-listing button {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }

  .edit-car-listing button[type="submit"] {
    background-color: #4caf50;
    color: #ffffff;
  }

  .edit-car-listing button[type="submit"]:hover {
    background-color: #45a049;
  }

  .edit-car-listing button:not([type="submit"]) {
    background-color: #f1f1f1;
    color: #000;
    margin-left: 10px;
  }

  .edit-car-listing button:not([type="submit"]):hover {
    background-color: #ddd;
  }
